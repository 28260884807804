import { __ } from '@wordpress/i18n';
import { registerCheckoutFilters } from '@woocommerce/blocks-checkout';
import './style.scss';

/**
 * Woocommerce Block Integration Script Data
 */
import { getSetting } from '@woocommerce/settings';
const scriptData = getSetting( 'sc-woo-filters_data' );

registerCheckoutFilters( 'sc-woo-cart-item', {

	/**
	 * Flag item as active seats
	 *
	 * @param value
	 * @param extensions
	 * @param args
	 * @returns {*}
	 */
	cartItemClass: ( value, extensions, args ) => {

		value += 'seat-block';

		if ( typeof scriptData[ 'seats' ][ args.cartItem.id ] !== 'undefined' ) {
			value += ' active-seats';
		}

		return value;
	},

	/**
	 * Recreate item name and append seat labels
	 *
	 * @param value
	 * @param extensions
	 * @param args
	 * @returns {string}
	 */
	itemName: ( value, extensions, args ) => {

		if ( typeof scriptData[ 'seats' ][ args.cartItem.id ] !== 'undefined' ) {

			value = '<div class="seat-generated">';
			value += '<span class="product-name">' + args.cartItem.name + '</span>';

			scriptData[ 'seats' ][ args.cartItem.id ].forEach( function ( seat, index ) {

				value += '<span class="seat-label" data-product_id="' + args.cartItem.id + '">'

				if ( seat[ 'seat_label' ] ) {

					if ( 'cart' == args.context ) {
						value += '<span class="tc_cart_remove_icon tc_cart_seat_remove" title="Remove from Cart" data-ticket-type-id="' + args.cartItem.id + '" data-seat-sign="' + seat[ 'seat_label' ] + '" data-seat-id="' + seat[ 'seat_id' ] + '" data-chart-id="' + seat[ 'chart_id' ] + '"><i class="fa fa-times" aria-hidden="true"></i></span>';
					}

				} else {
					value += tc_seat_charts_block.standee_qty + ': ' + seat[ 'quantity' ];
				}

				value += seat[ 'seat_label' ];
				value += '</span>';
			});

			value += '</div>';
		}

		return value;
	}
} );
